import React, { useContext } from 'react';
// @ts-ignore
import { Hourglass } from 'react-spinners-css';
import { ThemeContext, Theme, ThemeConstantProvider } from 'unifyre-react-helper';
import { RootState } from '../common/RootState';
import { connect } from 'react-redux';

interface WaitingProps { waiting: boolean }

function Waiting(props: WaitingProps) {
    const theme = useContext(ThemeContext);
    const styles = themedStyles(theme);
    if (!props.waiting) {
        return (<div />);
    }

    return (
        <div style={styles.container}>
            <div style={styles.subContainer}>
            <Hourglass color={theme.get(Theme.Colors.bkgShade3)} />
            </div>
        </div>
    )
}

const themedStyles = (theme: ThemeConstantProvider) => ({
    container: {
        display: 'absolute',
        zIndex: 999,
        top: 0,
        button: 0,
        left: 0,
        right: 0,
        backgroudColor: theme.get(Theme.Colors.bkgShade0),
        opacity: 0.7,
    },
    subContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    }
});

function mapStateToProps(state: RootState): WaitingProps {
    return { waiting: state.ui.flags.waiting };
}

export const WaitingContainer = connect(mapStateToProps, () => ({}))(Waiting);