import React, {useEffect} from 'react';
import { DashboardDispatch, Dashboard } from './Dashboard';
import { Switch, Route } from 'react-router-dom';
import {
    Page, Row, ThemedText, Gap,
    // @ts-ignore
} from 'unifyre-web-components';
import { connect } from 'react-redux';
import { DashboardProps } from '../../common/RootState';
import { AccountContainer } from '../account/AccountContainer';
import { BuyContainer, SellContainer } from '../buySell/BuySellContainer';
import { intl } from 'unifyre-react-helper';
import { Utils } from '../../common/Utils';
import { OrderContainer } from '../order/OrderContainer';

function DashboardComponent(props: DashboardProps&DashboardDispatch) {
    const {onLoad} = props;
    useEffect(() => {
        onLoad();
    }, [onLoad]);
    const queryAction = Utils.getQueryparam('action');
    if (props.initialized) {
        // Render the routes
        return (
            <>
              <Switch>
                <Route path='/account'>
                  <AccountContainer />
                </Route>
                <Route path='/buy'>
                  <BuyContainer />
                </Route>
                <Route path='/sell'>
                  <SellContainer />
                </Route>
                <Route path='/order/:orderId'>
                  <OrderContainer />
                </Route>
                <Route path='/reservation/:reservationId'>
                  <OrderContainer />
                </Route>
                <Route path='/'>
                  {queryAction === 'sell' ? <SellContainer /> : <BuyContainer />}
                </Route>
              </Switch>
            </>
        );
    }

    const fatalError = props.fatalError ? (
      <>
        <Row withPadding centered>
          <ThemedText.H2 >{intl('fatal-error-heading')}</ThemedText.H2>
        </Row>
        <Row withPadding centered>
          <ThemedText.H3 >{props.fatalError}</ThemedText.H3>
        </Row>
      </>
    ) : (
      <Row withPadding centered>
          <ThemedText.H2>Connecting...</ThemedText.H2>
      </Row>
    );

    return (
        <Page>
            <Gap />
            <Gap />
            <Gap />
            <Gap />
            {fatalError}
        </Page>
    );
}

export const DashboardContainer = connect(
  Dashboard.mapStateToProps, Dashboard.mapDispatchToProps)(DashboardComponent);