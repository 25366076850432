import React from 'react';
import {
    Page, PageTopPart, Gap, ThemedText, Row, InputGroupAddon, ThemedLink,
    ThemedButton,
    // @ts-ignore
} from 'unifyre-web-components';
import { AccountProps } from '../../common/RootState';
import { AccountDispatch, Account } from './Account';
import { connect } from 'react-redux';
import { intl } from 'unifyre-react-helper';

const WYRE_HELP_URL = 'https://support.sendwyre.com/en/';

function translateAccountStatus(status: string) {
    switch(status) {
        case 'OPEN':
            return 'Action required';
        case 'PENDING':
            return 'Pending approval';
        default:
            return 'Ready'
    }
}

function AccountComponent(props: AccountProps & AccountDispatch) {
    const action = props.actionRequired ? (
        <Row withPadding>
            <ThemedButton text={'Submit for verification'} />
        </Row>
    ) : undefined;


    const wyreInfoForm = (
        <React.Fragment>
            <Row withPadding>
                <ThemedText.SMALL>Individual Legal Name (e.g John Doe)</ThemedText.SMALL>
                {/* individualLegalName */}
            </Row>
            <Row withPadding>
                <InputGroupAddon value={'John Doe'} disabled={false} type={'string'} />
            </Row>
            <Row withPadding>
                <ThemedText.SMALL>Cell Phone Number (e.g +11111111111)</ThemedText.SMALL>
                {/* individualCellphoneNumber */}
            </Row>
            <Row withPadding>
                <InputGroupAddon value={'+11111111111'} disabled={false} />
            </Row>
            <Row withPadding>
                <ThemedText.SMALL>Date of Birth (e.g date format)</ThemedText.SMALL>
                {/* individualDateOfBirth */}
            </Row>
            <Row withPadding>
                <InputGroupAddon value={'05/25/1940'} disabled={false} type={'date'} />
            </Row>
            <Row withPadding>
                <ThemedText.SMALL>Individual Residence Address (e.g date format)</ThemedText.SMALL>
                {/* individualResidenceAddress {
                "street1": "1 Market St",
                "street2": "Suite 402",
                "city": "San Francisco",
                "state": "CA",
                "postalCode": "94105",
                "country": "US"
                },*/}
            </Row>
            <Row withPadding>
                <InputGroupAddon value={'New York'} disabled={false} />
            </Row>
        </React.Fragment>
    ); //more keys needed

    return (
        <Page>
            <PageTopPart>
                <Gap />
                <Row centered>
                    <ThemedText.H2>{intl('your-account')}</ThemedText.H2>
                </Row>
                <Gap />
                <Row withPadding>
                    <ThemedText.SMALL>{intl('display-name-caps')}</ThemedText.SMALL>
                </Row>
                <Row withPadding>
                    <InputGroupAddon value={`${props.displayName}`} disabled={true} />
                </Row>
                <Row withPadding>
                    <ThemedText.SMALL>WYRE ACCOUNT ID</ThemedText.SMALL>
                </Row>
                <Row withPadding>
                    <InputGroupAddon value={`${props.wyreAccountId}`} disabled={true} />
                </Row>
                <Row withPadding>
                    <ThemedText.SMALL>ACCOUNT STATUS</ThemedText.SMALL>
                </Row>
                <Row withPadding>
                    <InputGroupAddon value={`${translateAccountStatus(props.accountStatus)}`} disabled={true} />
                </Row>
                {props.accountStatus !== 'APPROVED' ? (<React.Fragment>
                    <Row withPadding>
                        <ThemedText.SMALL>your Account status must be "APPROVED" for you to be able to transact</ThemedText.SMALL>
                    </Row>
                </React.Fragment>) : undefined}
                <Gap />
            </PageTopPart>
            {props.actionRequired ? (
                <Row withPadding>
                    <ThemedText.H3>Please provide the following data for verification</ThemedText.H3>
                </Row>
            ) : undefined}
            {wyreInfoForm}
            {props.accountStatus === "APPROVED" ?
                (<React.Fragment>
                    <Row withPadding>
                        <ThemedText.SMALL>PAYMENT METHOD STATUS</ThemedText.SMALL>
                    </Row>
                    <Row withPadding>
                        <InputGroupAddon value={`${props.wyrePaymentMethodStatus}`} disabled={true} />
                    </Row>
                    <ThemedText.SMALL>your Payment method status must be "APPROVED" for you to be able to transact</ThemedText.SMALL>
                </React.Fragment>) : undefined}
            <Gap />
            {action}
            <Row withPadding centered>
                <ThemedLink text={'Contact wyre support'}
                    onClick={() => { window.location = WYRE_HELP_URL as any; }} />
            </Row>
        </Page>
    );
}

export const AccountContainer = connect(Account.mapStateToProps, Account.mapDispatchToProps)(AccountComponent);