import {Dispatch, AnyAction} from 'redux';
import { addAction, CommonActions } from '../common/Actions';
import { JsonRpcRequest, ValidationUtils, Injectable } from 'ferrum-plumbing';
import fetch from 'cross-fetch';
import { FullUserData, WyreAccount, WyreOrder, WyreReservation } from '../common/Types';
import { WyreConfig } from '../common/RootState';
import { intl } from 'unifyre-react-helper';

// const BACKEND = 'http://172.30.219.218:8080'; // TODO: BACKEND HERE
// const BACKEND = 'http://localhost:8080'; // TODO: BACKEND HERE
const BACKEND = 'https://mkeldwiw63.execute-api.us-east-2.amazonaws.com/default/wyre-backend'; 

export const WyreServiceActions = {
    TOKEN_NOT_FOUND_ERROR: 'TOKEN_NOT_FOUND_ERROR',
    API_ERROR: 'API_ERROR',
    AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
    AUTHENTICATION_COMPLETED: 'AUTHENTICATION_COMPLETED',
    USER_DATA_RECEIVED: 'USER_DATA_RECEIVED',

    CREATE_ACCOUNT_COMPLETED: 'CREATE_ACCOUNT_COMPLETED',
    CREATE_ACCOUNT_FAILED: 'CREATE_ACCOUNT_FAILED',

    CREATE_PAYMENT_METHOD_COMPLETED: 'CREATE_PAYMENT_METHOD_COMPLETED',
    CREATE_PAYMENT_METHOD_FAILED: 'CREATE_PAYMENT_METHOD_FAILED',

    CREATE_TRANSFER_COMPLETED: 'CREATE_TRANSFER_COMPLETED',
    CREATE_TRANSFER_FAILED: 'CREATE_TRANSFER_FAILED',

    GET_TRANSFER_COMPLETED: 'GET_TRANSFER_COMPLETED',
    GET_TRANSFER_FAILED: 'GET_TRANSFER_FAILED',

    WYRE_CLIENT_CONFIG_RECEIVED: 'WYRE_CLIENT_CONFIG_RECEIVED',

    GET_ORDER_FAILED: 'GET_ORDER_FAILED',
    GET_ORDER_COMPLETED: 'GET_ORDER_COMPLETED',

    WYRE_API_FAILED: 'WYRE_API_FAILED',
};

const Actions = WyreServiceActions;

export class WyreClient implements Injectable {
    public static readonly instance = new WyreClient();
    private jwtToken: string = '';

    __name__() { return 'WyreClient'; }

    async signInToServer(dispatch: Dispatch<AnyAction>): Promise<FullUserData | undefined> {
        const storedToken = localStorage.getItem('SIGNIN_TOKEN');
        const token = (window.location.href.split("token=")[1] || '').split("&")[0] || storedToken;
        if (!token) {
            dispatch(addAction(Actions.TOKEN_NOT_FOUND_ERROR, {}));
            return;
        }

        try {
            dispatch(addAction(CommonActions.WAITING, { source: 'signInToServer' }));
            const res = await this.api({
                command: 'signInToServer', data: {token}, params: [] } as JsonRpcRequest);
            const {fullUserData, userProfile, session} = res;
            if (!session) {
                dispatch(addAction(Actions.AUTHENTICATION_FAILED, { message: 'Could not connect to Unifyre' }));
                return;
            }
            localStorage.setItem('SIGNIN_TOKEN', token);
            this.jwtToken = session;
            // Get client config
            const clientConfig = await this.api({ command: 'getClientConfig', data: {}, params: [] });
            if (!res) {
                dispatch(addAction(Actions.AUTHENTICATION_FAILED, {
                    message: 'Could not get wyre configuration' }));
                return;
            }
            dispatch(addAction(Actions.AUTHENTICATION_COMPLETED, { }));
            dispatch(addAction(Actions.USER_DATA_RECEIVED, { fullUserData, userProfile }));
            dispatch(addAction(Actions.WYRE_CLIENT_CONFIG_RECEIVED, { clientConfig }));
            return fullUserData;
        } catch (e) {
            console.error('Error sigining in', e);
            dispatch(addAction(Actions.AUTHENTICATION_FAILED, { message: 'Could not connect to Unifyre' }));
        } finally {
            dispatch(addAction(CommonActions.WAITING_DONE, { source: 'signInToServer' }));
        }
    }

    async getOrCreateAccount(dispatch: Dispatch<AnyAction>, email: string): Promise<WyreAccount | undefined> {
        try {
            ValidationUtils.isTrue(!!email, '"email" must be provided');
            dispatch(addAction(CommonActions.WAITING, { source: 'getOrCreateAccount' }));
            const account = await this.api({
                command: 'getOrCreateAccount', data: {email}, params: [] } as JsonRpcRequest);
            if (!account) {
                dispatch(addAction(Actions.CREATE_ACCOUNT_FAILED, {
                    message: 'Could not create an account' }));
                return;
            }
            dispatch(addAction(Actions.CREATE_ACCOUNT_COMPLETED, { account }));
            await this.refresh(dispatch);
            return account;
        } catch (e) {
            dispatch(addAction(Actions.CREATE_ACCOUNT_FAILED, { message: e.message }));
        } finally {
            dispatch(addAction(CommonActions.WAITING_DONE, { source: 'getOrCreateAccount' }));
        }
    }

    async createPaymentMethod(dispatch: Dispatch<AnyAction>, publicToken: string, country: string = 'US') {
        try {
            ValidationUtils.isTrue(!!publicToken, '"publicToken" must be provided');
            dispatch(addAction(CommonActions.WAITING, { source: 'createPaymentMethod' }));
            const paymentMethod = await this.api({
                command: 'createPaymentMethod', data: {publicToken, country}, params: [] } as JsonRpcRequest);
            if (!paymentMethod) {
                dispatch(addAction(Actions.CREATE_PAYMENT_METHOD_FAILED, {
                    message: 'Could not create a payment method' }));
                return;
            }
            dispatch(addAction(Actions.CREATE_PAYMENT_METHOD_COMPLETED, { paymentMethod }));
            await this.refresh(dispatch);
        } catch (e) {
            dispatch(addAction(Actions.CREATE_PAYMENT_METHOD_FAILED, { message: e.message }));
        } finally {
            dispatch(addAction(CommonActions.WAITING_DONE, { source: 'createPaymentMethod' }));
        }
    }

    async getTransfers(dispatch: Dispatch<AnyAction>) {
        try {
            dispatch(addAction(CommonActions.WAITING, { source: 'getTransfer' }));
            const transactions = await this.api({
                command: 'getTransfers', data: {}, params: [] } as JsonRpcRequest);
            dispatch(addAction(Actions.GET_TRANSFER_COMPLETED, { transactions }));
        } catch (e) {
            dispatch(addAction(Actions.GET_TRANSFER_FAILED, { message: e.message }));
        } finally {
            dispatch(addAction(CommonActions.WAITING_DONE, { source: 'getTransfers' }));
        }
    }

    async createTransfer(dispatch: Dispatch<AnyAction>,
        source: string,
        sourceCurrency: string,
        sourceAmount: string,
        dest: string,
        destCurrency: string) {
        try {
            dispatch(addAction(CommonActions.WAITING, { source: 'createTransfer' }));
            const transaction = await this.api({
                command: 'createTransfer', data: {
                    source, sourceCurrency, sourceAmount, dest, destCurrency}, params: [] } as JsonRpcRequest);
            if (!transaction) {
                dispatch(addAction(Actions.CREATE_TRANSFER_FAILED, {
                    message: 'Could not create a payment method' }));
                return;
            }
            dispatch(addAction(Actions.CREATE_TRANSFER_COMPLETED, { transaction }));
        } catch (e) {
            dispatch(addAction(Actions.CREATE_TRANSFER_FAILED, { message: e.message }));
        } finally {
            dispatch(addAction(CommonActions.WAITING_DONE, { source: 'createTransfer' }));
        }
    }

    async reserveOrder(dispatch: Dispatch<AnyAction>, 
        fiatAmount: string,
        fiatCurrency: string,
        cryptoCurrency: string,
        cryptoAddress: string,
        paymentMethod: string,
        ): Promise<WyreReservation | undefined> {
        try {
            dispatch(addAction(CommonActions.WAITING, { source: 'reserveOrder' }));
            const res = await this.api({command: 'reserveOrder', data: {
                fiatAmount, fiatCurrency, cryptoCurrency, cryptoAddress, paymentMethod}, params: [] },) as WyreReservation|undefined;
            if (!res) {
                dispatch(addAction(Actions.WYRE_API_FAILED, { message: 'Could not reserve the order' }));
                return;
            }
            return res;
        } catch (e) {
            dispatch(addAction(Actions.WYRE_API_FAILED, { message: e.message }));
        } finally {
            dispatch(addAction(CommonActions.WAITING_DONE, { source: 'reserveOrder' }));
        }
    }

    async getOrder(dispatch: Dispatch<AnyAction>, orderId: string) {
        try {
            dispatch(addAction(CommonActions.WAITING, { source: 'getOrder' }));
            const order = await this.api({command: 'getOrder', data: {orderId}, params: [] },) as WyreOrder|undefined;
            if (!order || !order.id) {
                dispatch(addAction(Actions.GET_ORDER_FAILED, { message: intl('order-not-found') }));
                return;
            }
            dispatch(addAction(Actions.GET_ORDER_COMPLETED, {order}));
        } catch (e) {
            console.error('getOrder', e);
            dispatch(addAction(Actions.GET_ORDER_FAILED, { message: e.message }));
        } finally {
            dispatch(addAction(CommonActions.WAITING_DONE, { source: 'getOrder' }));
        }
    }

    async getOrderFromReservation(dispatch:Dispatch<AnyAction>, reservationId: string) {
        try {
            dispatch(addAction(CommonActions.WAITING, { source: 'getOrderFromReservation' }));
            const resv = await this.api({command: 'getHookContent', data: {
                referenceId: reservationId}, params: [] },) as any;
            if (!resv || !resv.orderId) {
                dispatch(addAction(Actions.GET_ORDER_FAILED, { message: intl('order-not-found') }));
                return;
            }
            console.log('Received order ID from reservation ', resv.orderId);
            return this.getOrder(dispatch, resv.orderId);
        } catch (e) {
            console.error('getOrderFromReservation', e);
            dispatch(addAction(Actions.GET_ORDER_FAILED, { message: e.message }));
        } finally {
            dispatch(addAction(CommonActions.WAITING_DONE, { source: 'getOrderFromReservation' }));
        }
    }

    dashboardUrlForTransfer(env: 'test'|'prod', transferId: string) {
        return `${this.dashBaseUrl(env)}/track/${transferId}`;
    }

    private dashBaseUrl(env: 'test' | 'prod') {
        return env === 'test' ? 'https://dash.testwyre.com' : 'https://dash.sendwyre.com';
    }

    private async refresh(dispatch: Dispatch<AnyAction>) {
        const fullUserData = await this.api({
            command: 'getUserData', data: {}, params: [] } as JsonRpcRequest);
        if (fullUserData) {
            dispatch(addAction(Actions.USER_DATA_RECEIVED, { fullUserData }));
        }
    }

    private async api(req: JsonRpcRequest): Promise<any> {
        try {
            const res = await fetch(BACKEND, {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(req),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.jwtToken}`
                },
            });
            const resText = await res.text();
            if (Math.round(res.status / 100) === 2) {
                return resText ? JSON.parse(resText) : undefined;
            }
            const error = resText;
            console.error('Server returned an error when calling ', req, {
                status: res.status, statusText: res.statusText, error});
            throw new Error('Error calling backend: ' + error);
        } catch (e) {
            console.error('Error calling api with ', req, e);
            throw e;
        }
    }
}

const defaultWyreConfig = {
    env: 'test', accountId: 'AC_JZRHZANBEFP',
} as WyreConfig;

export function WyreConfigReducer(state: WyreConfig = defaultWyreConfig, action: AnyAction) {
    switch (action.type) {
        case Actions.WYRE_CLIENT_CONFIG_RECEIVED:
            const {clientConfig} = action.payload;
            return {...clientConfig};
        default:
            return state;
    }
}