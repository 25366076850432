import { RootState, AccountProps } from "../../common/RootState";
import { AnyAction } from "redux";
import { WyreServiceActions } from "../../services/WyreClient";
import { FullUserData, WyreAccount } from "../../common/Types";
import { AppUserProfile } from "unifyre-extension-sdk/dist/client/model/AppUserProfile";

export interface AccountDispatch {
}

const defaultAccountProps = {
    accountStatus: 'OPEN',
    actionRequired: false,
    displayName: '',
    email: '',
    lastUpdate: '',
    wyreAccountId: '',
} as AccountProps;

function mapStateToProps(state: RootState): AccountProps {
    const account: WyreAccount = (state.data.userData?.data?.accounts || [])[0] || {};
    return {
        ...state.ui.account,
        displayName: state.data.userData?.profile?.displayName || '',
        email: state.data.userData?.profile?.email || '',
        id: state.data.userData?.profile?.userId || '',
        accountStatus: account.status,
        wyrePaymentMethodStatus: state.data.userData?.data?.paymentMethods ? state.data.userData?.data?.paymentMethods[0]?.status : undefined,
        actionRequired: account.status === 'OPEN',
    } as AccountProps;
}

function reduce(state: AccountProps = defaultAccountProps, action: AnyAction): AccountProps {
    switch (action.type) {
        case WyreServiceActions.USER_DATA_RECEIVED:
            // Use first account. TODO: Support multiple accounts in future
            const {fullUserData} = action.payload as {fullUserData: FullUserData};
            const account = fullUserData.accounts[0];
            if (!account) {
                return state;
            }
            return {...state,
                id: account.id,
                accountStatus: account.status,
                actionRequired: false,
            };
        default:
            return state;
    }
}

export const Account = {
    mapDispatchToProps: () => ({}),
    mapStateToProps,
    reduce,
}