import React from 'react';
import './App.css';
import {ThemeContext, ThemeConstantProvider, Theme, defaultDarkThemeConstantsBuilder} from 'unifyre-react-helper';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { Provider as AlertProvider } from 'react-alert'
import { WaitingContainer } from './components/Waiting';
import { DashboardContainer } from './pages/dashboard/DashboardContainer';
import { Provider } from 'react-redux';
import { store } from './common/Store';

// the style contains only the margin given as offset
// options contains all alert given options
// message is the alert message
// close is a function that closes the alert
const AlertTemplate = (p: { style: any, options: any, message: any, close: any }) => (
  <div style={p.style}>
    {p.options.type === 'info' && '!'}
    {p.options.type === 'success' && ':)'}
    {p.options.type === 'error' && ':('}
    {p.message}
    <button onClick={p.close}>X</button>
  </div>
)

function App() {
  let themeProvider = new ThemeConstantProvider('unifyre', defaultDarkThemeConstantsBuilder
  .set(Theme.Font.main, "'Open Sans', sans-serif").build());

  return (
      <ThemeContext.Provider value={themeProvider}>
          <Provider store={store}>
            <AlertProvider template={AlertTemplate} >
              <Router>
                <DashboardContainer />
              </Router>
              <WaitingContainer />
            </AlertProvider>
          </Provider>
      </ThemeContext.Provider>
  );
}

export default App;
