import { combineReducers, AnyAction } from "redux";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { Account } from "../pages/account/Account";
import { BuySell } from "../pages/buySell/BuySell";
import { FullUserData } from "./Types";
import { CommonActions } from "./Actions";
import { WyreServiceActions, WyreConfigReducer } from "../services/WyreClient";
import { userPreferenceReducer } from "../services/UserPreferenceService";
import { Order } from "../pages/order/Order";
import { AppUserProfile } from "unifyre-extension-sdk/dist/client/model/AppUserProfile";

function flags(state: { waiting: boolean } = { waiting: false }, action: AnyAction) {
    switch (action.type) {
        case CommonActions.WAITING:
            return { waiting: true };
        case CommonActions.WAITING_DONE:
            return { waiting: false };
        default:
            return state;
    }
}

const defaultFullUserData = {
    data: {
        userId:'',
        accounts: [] as any[],
        lastAccess: 0,
        paymentMethods: [],
        userProfile: {} as any,
        settings: {} as any,
        version: 0,
        transactions: [] as any,
    }, 
    profile: { } as AppUserProfile,
} as { data: FullUserData, profile: AppUserProfile };

function userData(state: { data: FullUserData, profile: AppUserProfile} = defaultFullUserData,
        action: AnyAction) {
    switch(action.type) {
        case WyreServiceActions.USER_DATA_RECEIVED:
            const {fullUserData, userProfile} = action.payload;
            return {...state, data: fullUserData, profile: userProfile};
    }
    return state;
}

const data = combineReducers({
    userData,
    userPreference: userPreferenceReducer,
    wyreConfig: WyreConfigReducer,
});

const ui = combineReducers({
    flags,
    dashboard: Dashboard.reduce,
    account: Account.reduce,
    buySell: BuySell.reduce,
    order: Order.reduce,
});

export const rootReducer = combineReducers({ data, ui });
