import {Container, ValidationUtils} from 'ferrum-plumbing';
import { WyreClient } from '../services/WyreClient';
import { IntlManager } from 'unifyre-react-helper';
import { stringsEn } from '../intl/en';
import { UserPreferenceService } from '../services/UserPreferenceService';
import { RatesService } from '../services/RatesService';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';

export class IocModule {
    private static _container: Container;
    static async init(dispatch: Dispatch<AnyAction>) {
        if (!!IocModule._container) {
            return IocModule._container;
        }

        const c = new Container();
        c.registerSingleton(WyreClient, c => new WyreClient());
        c.registerSingleton(UserPreferenceService, c => new UserPreferenceService());
        c.registerSingleton(RatesService, c => new RatesService());
        IntlManager.instance.load([stringsEn], 'en-US');
        IocModule._container = c;

        // init other dependencies
        c.get<UserPreferenceService>(UserPreferenceService).init(dispatch);
        c.get<RatesService>(RatesService).init(dispatch);
    }

    static container() {
        ValidationUtils.isTrue(!!IocModule._container, 'Container not initialized');
        return IocModule._container;
    }
}

export function inject<T>(type: any): T {
    return IocModule.container().get<T>(type);
}