import React, { CSSProperties, useContext } from 'react';
import {
    Page, PageTopPart, Gap, ThemedText, Row, InputGroupAddon, ThemedLink,
    ThemedButton, List, ListItem, InputDropDown,
    // @ts-ignore
} from 'unifyre-web-components';
import { BuySellDispatch, Buy, Sell, formatter, BuySellProps } from './BuySell';
import { connect } from 'react-redux';
import { intl, ThemeConstantProvider, Theme, ThemeContext } from 'unifyre-react-helper';
import { useHistory } from 'react-router';
import { Utils } from '../../common/Utils';

function BuySellPage(props: BuySellProps&BuySellDispatch) {
    const theme = useContext(ThemeContext);
    const styles = themedStyles(theme);
    const history = useHistory();
    const title = props.action === 'sell' ? `Sell ${props.cryptoCurrency}` : `Buy ${props.cryptoCurrency}`;
    const balance = props.action === 'sell' ? (
        <>
        <Row withPadding>
            <ThemedText.SMALL>{intl('balance-caps')}</ThemedText.SMALL>
        </Row>
        <Row withPadding>
            <InputGroupAddon value={`${props.cryptoBalance} ${props.cryptoCurrency}`} disabled={true}/>
        </Row>
        </>
    ) : (undefined);
    if (props.action === 'sell' && 1 === 1) { // adding & 1===1 because ts is so smart!
        return (
        <Page>
            <PageTopPart>
                <Gap />
                <Row withPadding centered>
                    <ThemedText.H2>{'Coming soon'}</ThemedText.H2>
                </Row>
                <Gap />
            </PageTopPart>
        </Page>);
    }

    const targetCurrencySelector = (
        <Row withPadding>
        <InputDropDown
            items={props.supportedFiatCurrencies.map(c => ({key: c, label: c}))}
            selectedKey={props.fiatCurrency}
            onItemSelected={(key: string) => props.onFiatCurrencyChanged(key)}
            itemRenderer={(item: any) => <span>{item.label}</span>}
        />
        </Row>
    );

    const paymentMthods = ['debit', Utils.platform() === 'iOS' ? 'apple-pay' : '' ].filter(Boolean);

    const paymentMethodSelector = (
        <>
            <List items={paymentMthods}
                itemRenderer={ (itemIdx: any) => (
                    <ListItem key={itemIdx.index} onClick={() => props.onFiatSourceChanged(itemIdx.item)}>
                        <input type="radio" checked={props.fiatSource === itemIdx.item} onChange={() => { }}/>
                        <ThemedText.H2>{intl('fiat-source-' + itemIdx.item)}</ThemedText.H2>
                    </ListItem>
                )}
            />
        </>
    );

    let buttonMessage = intl('btn-continue', { action: props.action });
    switch(props.fiatSource) {
        case 'ach':
            if (!props.accountIsApproved)  {
                buttonMessage = intl('btn-complete-account');
            }
            break;
        case 'debit':
            break;
    }
    let youSpendTitle = intl('you-spend-caps');
    let youNeed = props.userCryptoForInput ?
        `${formatter.format(props.fiatAmount, true)} ${props.fiatCurrency}` :
        `${formatter.format(props.cryptoAmount, false)} ${props.cryptoCurrency}`;
    switch(props.action) {
        case 'sell':
            youSpendTitle = props.userCryptoForInput ? intl('you-receive-caps') : intl('you-spend-caps');
            break;
        case 'buy':
            youSpendTitle = props.userCryptoForInput ? intl('you-spend-caps') : intl('you-receive-caps');
            break;
    }

    const cryptoAddress = props.cryptoAddress;
    return (
        <Page>
            <PageTopPart>
                <Gap />
                <Row withPadding centered>
                    <ThemedText.H2>{title}</ThemedText.H2>
                </Row>
                <Gap />
            </PageTopPart>
            <Gap />
            <Row withPadding>
                <ThemedText.SMALL>{intl('address-caps')}</ThemedText.SMALL>
            </Row>
            <Row withPadding>
                <ThemedLink
                    text={cryptoAddress}
                    onClick={() => window.open(props.cryptoAddressLink, 'blank')}
                />
            </Row>
            {targetCurrencySelector}
            <Row withPadding>
                <ThemedText.SMALL>{(props.action === 'sell' ? 'SELL AMOUNT' : 'BUY AMOUNT')}</ThemedText.SMALL>
            </Row>
            <Row withPadding>
                <InputGroupAddon
                    value={props.inputAmount}
                    placeholder={'AMOUNT'}
                    onChange={props.onAmountChanged}
                    inputMode={'decimal'}
                    editable={undefined}
                    rightAddon={<a style={styles.currencyOnInput}
                            onClick={() =>
                            props.onUseCryptoForInputToggle(props.cryptoAmount, props.fiatAmount)}>
                        {props.userCryptoForInput ? props.cryptoCurrency : props.fiatCurrency}
                    </a>}
                    />
            </Row>
            {balance}
            <Row withPadding>
                <ThemedText.SMALL>{youSpendTitle}</ThemedText.SMALL>
            </Row>
            <Row withPadding>
                <InputGroupAddon value={youNeed} disabled={true} />
            </Row>
            <Row withPadding>
                <ThemedText.SMALL>{intl('fee-not-included')}</ThemedText.SMALL>
            </Row>
            {/*<Row withPadding>
                <InputGroupAddon value={`${
                    formatter.format(props.fee, props.action === 'buy')} ${props.feeCurrency}`} disabled={true} />
            </Row> */}
            {paymentMethodSelector}
            <Row withPadding>
                <ThemedText.P>
                    {intl('buy-notes')}
                </ThemedText.P>
            </Row>
            <Gap />
            <Row withPadding>
                <ThemedButton
                    disabled={props.buttonDisabled}
                    text={buttonMessage}
                    onClick={() => props.onSubmit(props, history)} />
            </Row>
            <Gap />
            <Gap />
        </Page>
    );
}

const themedStyles = (theme: ThemeConstantProvider) => ({
    currencyOnInput: {
        width: 85,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.get(Theme.Colors.bkgShade3),
        color: theme.get(Theme.Colors.textColor),
        borderRadius: theme.get(Theme.Spaces.line) as any * 2,
    } as CSSProperties,
});

export const BuyContainer = connect(Buy.mapStateToProps('buy'), Buy.mapDispatchToProps)(BuySellPage);

export const SellContainer = connect(Sell.mapStateToProps('sell'), Sell.mapDispatchToProps)(BuySellPage);