import { RootState, OrderState } from "../../common/RootState";
import { AnyAction, Dispatch } from "redux";
import { WyreClient, WyreServiceActions } from "../../services/WyreClient";
import { inject } from "../../common/IocModule";

const defaultOrderProps = {
    error: 'No order',
    order: {} as any,
} as OrderState;

export interface OrderProps extends OrderState {
    env: 'test' | 'prod';
}

export interface OrderDispatch {
    loadOrder: (orderId: string) => void;
    loadOrderByReservation: (reservationId: string) => void;
    onCloseClicked: () => void;
    onTrackTransfer: (env: 'test' | 'prod', txId: string) => void;
}

const mapStateToProps = (rootState: RootState) => ({
    ...rootState.ui.order,
    env: rootState.data.wyreConfig.env,
} as OrderProps);

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    loadOrder: async orderId => {
        const client = inject<WyreClient>(WyreClient);
        client.getOrder(dispatch, orderId);
    },
    loadOrderByReservation: async reservationId => {
        const client = inject<WyreClient>(WyreClient);
        client.getOrderFromReservation(dispatch, reservationId);
    },
    onCloseClicked: () => { window.location.href = 'https://app.unifyre.io' },
    onTrackTransfer: (env, transferId) => {
        const client = inject<WyreClient>(WyreClient);
        const url = client.dashboardUrlForTransfer(env, transferId);
        window.open(url, '_blank');
    }
} as OrderDispatch);

function reducer(state: OrderState = defaultOrderProps, action: AnyAction) {
    switch (action.type) {
        case WyreServiceActions.GET_ORDER_FAILED:
            const {message} = action.payload;
            return {...state, error: message};
        case WyreServiceActions.GET_ORDER_COMPLETED:
            const {order} = action.payload;
            return {...state, error: undefined, order};
        default:
            return state;
    }
}

export const Order = {
    mapStateToProps,
    mapDispatchToProps,
    reduce: reducer,
}