import { RootState, WyreConfig, BuySellState } from "../../common/RootState";
import { Dispatch, AnyAction } from "redux";
import { WyreServiceActions, WyreClient } from "../../services/WyreClient";
import { addAction } from "../../common/Actions";
import { RateServiceActions, RatesService, CurrencyFormatter } from "../../services/RatesService";
import { inject } from "../../common/IocModule";
import { UserPreferenceService } from "../../services/UserPreferenceService";
import { History } from 'history';
import { WyrePaymentMethod } from "../../common/Types";
import { Utils } from "../../common/Utils";

export const formatter = new CurrencyFormatter();
function unf(val: string) { return !!val ? formatter.unFormat(val) : val }
function format(val: string, isFiat: boolean) {
    return !!val ? formatter.format(val, isFiat) : val;
}
function isZero(val: string) {
    return !val || val === '0';
}

export interface BuySellProps extends BuySellState {
    accountIsApproved: boolean;
    wyreAccountId: string;
    userId: string;
    wyreConfig: WyreConfig,
    paymentMethodSupportsCurrency: boolean;
    supportedFiatCurrencies: string[];

    cryptoCurrency: string;
    cryptoAmount: string;
    cryptoBalance: string;
    cryptoAddress: string;
    cryptoAddressLink: string;

    fiatAddress: string;
    fiatAmount: string;
    usdAmount: string;
    fee: string;
    feeCurrency: string;
}

const BuySellActions = {
    FIAT_SOURCE_CHANGED: 'FIAT_SOURCE_CHANGED',
    FIAT_CURRENCY_CHANGED: 'FIAT_CURRENCY_CHANGED',
    USE_CRYPTO_FOR_INPUT_TOGGLED: 'USE_CRYPTO_FOR_INPUT_TOGGLED',
    AMOUNT_CHANGED: 'AMOUNT_CHANGED',
};

const Actions = BuySellActions;
const buySellStateDefault = {
    action: 'buy',
    inputAmount: '',
    buttonDisabled: true,
    fiatCurrency: '',
    userCryptoForInput: true,
    fiatSource: 'debit',
    showBuyAchWidget: false,
    showBuyWidget: false,
} as BuySellState;
const buySellPropsDefault = {
    ...buySellStateDefault,
    accountIsApproved: false,
    cryptoAddress: '',
    cryptoAddressLink: '',
    cryptoAmount: '',
    cryptoBalance: '',
    cryptoCurrency: '',
    fiatCurrency: '',
    fee: '',
    feeCurrency: '',
    fiatAddress: '',
    fiatAmount: '',
    usdAmount: '',
    paymentMethodSupportsCurrency: false,
    supportedFiatCurrencies: [],
    userId: '',
    wyreAccountId: '',
    wyreConfig: {} as any,
} as BuySellProps;


export interface BuySellDispatch {
    onSubmit: (props: BuySellProps, history: History) => Promise<void>;
    onAmountChanged: (amount: string) => void;
    onUseCryptoForInputToggle: (cryptoAmount: string, fiatAmount: string) => void;
    onFiatCurrencyChanged: (cur: string) => void;
    onFiatSourceChanged: (fiatSource: string) => void;
    onPublicTokenLoaded: (publicToken: string) => void;
}

function mapStateToProps(action: string){
    return function mstp(rootState: RootState): BuySellProps {
    const rateSvc = inject<RatesService>(RatesService);
    const state = rootState.ui.buySell;
    const addresses = ((rootState.data.userData?.profile?.accountGroups || [])[0] || {}).addresses || {};
    // TODO: Add symbol to AddressDetails 
    const cryptoCurrency = (addresses[0] as any).symbol;
    const cryptoBalance = addresses[0].balance;
    const cryptoAddress = addresses[0].addressWithChecksum || addresses[0].address;
    if (!cryptoCurrency) {
        return {...state, ...buySellPropsDefault};
    }
    const fiatCurrency = state.fiatCurrency || rootState.data.userPreference?.lastFiatCurrency || 'USD';
    // We assume all payment methods are ACH
    const userId = (rootState.data.userData?.data?.userId);
    let buttonDisabled = !cryptoCurrency || !cryptoAddress;
    const account = ((rootState.data.userData?.data?.accounts || [])[0] || {});
    const paymentMethodId = rootState.data.userData?.data?.settings?.activePaymentMethodId;
    const paymentMethod = ((rootState.data.userData?.data?.paymentMethods || [])
        .find(pm => pm.id === paymentMethodId) || {}) as WyrePaymentMethod;
    const accountIsApproved = account.status === 'APPROVED';
    
    const partial = {
        ...buySellPropsDefault,
        ...state,
        action,
        buttonDisabled: true,

        cryptoAddress,
        cryptoAddressLink: cryptoCurrency === 'btc' ? `https://btc.com/${cryptoAddress}` :
            `https://etherscan.io/address/${cryptoAddress}`,
        cryptoBalance,
        cryptoCurrency,
        fiatAddress: paymentMethod.srn || '',
        accountIsApproved,
        supportedFiatCurrencies: ['USD', 'EUR', 'GBP', 'AUD', 'CAD', 'BRL', 'MXN', 'HKD'
            ].filter(c => rateSvc.pairSupported(c, cryptoCurrency)),
        userId,
        wyreConfig: rootState.data.wyreConfig,
    } as BuySellProps;

    let inputAmount = state.inputAmount === '' ? state.inputAmountBeforeSwitch || '' :
        state.inputAmount;
    let unfInputAmount = unf(inputAmount) || '';
    try {
        if (action === 'buy') {
            const paymentMethodSupportsCurrency = paymentMethod &&
                (paymentMethod.chargeableCurrencies || []).indexOf(fiatCurrency) >= 0;
            const rateCalc = rateSvc.forBuy(cryptoCurrency, fiatCurrency);
            if (!rateCalc) { return partial; }
            let inputIsCrypto = state.userCryptoForInput;
            if (state.inputAmountBeforeSwitch) {
                inputIsCrypto = !inputIsCrypto;
            }
            const [calcedAmount, fee] = inputIsCrypto ?
                rateCalc!.spendToAquire(unfInputAmount) :
                    rateCalc!.aquireIfSpend(unfInputAmount);
            const fiatAmount = inputIsCrypto ? calcedAmount : unfInputAmount;
            const cryptoAmount = !inputIsCrypto ? calcedAmount : unfInputAmount;
            inputAmount = state.inputAmountBeforeSwitch ?
                (state.userCryptoForInput ? formatter.format(cryptoAmount, false) : 
                    formatter.format(fiatAmount, true))! :
                inputAmount;
            buttonDisabled = buttonDisabled || isZero(cryptoAmount) || isZero(fiatAmount);
            return {
                ...partial,
                fee,
                feeCurrency: fiatCurrency,
                cryptoAmount,
                fiatAmount,
                fiatCurrency,
                usdAmount: rateSvc.exchangeFiat(fiatCurrency, fiatAmount, 'USD'),
                inputAmount: inputAmount,
                buttonDisabled,
            } as BuySellProps;
        } else {
            const rateCalc = rateSvc.forSell(cryptoCurrency, fiatCurrency);
            if (!rateCalc) { return partial; }
            const [calcedAmount, fee] = state.userCryptoForInput ?
                rateCalc!.spendToAquire(inputAmount) :
                    rateCalc!.spendToAquire(inputAmount);
            const fiatAmount = state.userCryptoForInput ? calcedAmount : inputAmount;
            const cryptoAmount = !state.userCryptoForInput ? calcedAmount : inputAmount;
            buttonDisabled = buttonDisabled || isZero(cryptoAmount) || isZero(fiatAmount);
            return {
                ...partial,
                fee,
                feeCurrency: cryptoCurrency,
                cryptoAmount,
                fiatAmount,
                fiatCurrency,
                inputAmount: inputAmount,
                buttonDisabled,
            } as BuySellProps;
        }
    } catch (e) {
        console.error('BuySell.mapStateToProps', e);
        return partial;
    }
}}

function commonMapDispatchToProps(dispatch: Dispatch<AnyAction>): Partial<BuySellDispatch> {
    return {
        onFiatSourceChanged: fiatSource => {
            inject<UserPreferenceService>(UserPreferenceService).update(dispatch,
                {lastFiatSource: fiatSource});
            dispatch(addAction(Actions.FIAT_SOURCE_CHANGED, {fiatSource}))
        },
        onFiatCurrencyChanged: fiatCurrency => {
            inject<UserPreferenceService>(UserPreferenceService).update(dispatch,
                {lastFiatCurrency: fiatCurrency});
            dispatch(addAction(Actions.FIAT_CURRENCY_CHANGED, {fiatCurrency}))
        },
        onUseCryptoForInputToggle: (cryptoAmount, fiatAmount) =>
            dispatch(addAction(Actions.USE_CRYPTO_FOR_INPUT_TOGGLED, {cryptoAmount, fiatAmount})),
        onAmountChanged: amount => {
            inject<RatesService>(RatesService).init(dispatch); // In case rate timed out, get a new one
            dispatch(addAction(Actions.AMOUNT_CHANGED, {amount}))
        },
        onPublicTokenLoaded: async publicToken => {
            const wyreClient = inject<WyreClient>(WyreClient);
            return wyreClient.createPaymentMethod(dispatch, publicToken);
        },
    } as Partial<BuySellDispatch>;
}

function mapDispatchToPropsForBuy(dispatch: Dispatch<AnyAction>) {
    return {
        ...commonMapDispatchToProps(dispatch),
        onSubmit: async (props, history) => {
            if (props.fiatSource === 'debit' || props.fiatSource === 'apple-pay') {
                const wyreClient = inject<WyreClient>(WyreClient);
                const amount = formatter.unFormat(formatter.format(props.usdAmount, true) || '0') || '0';
                const order = await wyreClient.reserveOrder(dispatch,
                    amount, 'USD', props.cryptoCurrency, props.cryptoAddress,
                    props.fiatSource === 'apple-pay' ? 'apple-pay' : 'debit-card');
                if (order && order.url) {
                    const fullUrl = order.url +
                        '&redirectUrl=' + Utils.getRoute(`reservation/${order.reservation}`) +
                        '&failureRedirectUrl=' + Utils.getRoute(`reservation/${order.reservation}`) +
                        `&referenceId=${order.reservation}` +
                        (props.fiatSource === 'apple-pay' ? `&paymentMethod=apple-pay` : '');
                    window.location.href = fullUrl;
                }
            } else {
                if (props.accountIsApproved) {
                    // Create a transfer
                    const wyreClient = inject<WyreClient>(WyreClient);
                    return wyreClient.createTransfer(
                        dispatch,
                        props.fiatAddress,
                        props.fiatCurrency,
                        props.fiatAmount,
                        `${props.cryptoCurrency === 'BTC' ? 'bitcoin' : 'ethereum'}:${props.cryptoAddress}`,
                        props.cryptoCurrency,
                    );
                } else {
                    history.push('/account');
                }
            }
        }
    } as BuySellDispatch;
}

function mapDispatchToPropsForSell(dispatch: Dispatch<AnyAction>) {
    return {
        ...commonMapDispatchToProps(dispatch),
        onSubmit: async props => {
        }
    } as BuySellDispatch;
}

function reduce(state: BuySellState = buySellStateDefault, action: AnyAction): BuySellState {
    switch(action.type) {
        case WyreServiceActions.USER_DATA_RECEIVED:
            return {...state, errorMsg: undefined};
        case Actions.FIAT_SOURCE_CHANGED:
            const {fiatSource} = action.payload;
            return {...state, fiatSource};
        case Actions.USE_CRYPTO_FOR_INPUT_TOGGLED:
            const userCryptoForInput = !state.userCryptoForInput;
            return {...state, userCryptoForInput,
                inputAmount: state.inputAmountBeforeSwitch || '',
                inputAmountBeforeSwitch: state.inputAmount, errorMsg: undefined
            };
        case Actions.AMOUNT_CHANGED:
            const {amount} = action.payload;
            return {...state, inputAmount: amount, inputAmountBeforeSwitch: undefined,
                errorMsg: undefined };
        case RateServiceActions.FETCH_RATES_SUCEEDED:
            return {...state};
        case Actions.FIAT_CURRENCY_CHANGED:
            const {fiatCurrency} = action.payload
            return {...state, fiatCurrency, errorMsg: undefined};
        case WyreServiceActions.WYRE_API_FAILED:
            const {message} = action.payload;
            return {...state, errorMsg: message};
        default:
            return state;
    }
}

export const Buy = {
    mapDispatchToProps: mapDispatchToPropsForBuy,
    mapStateToProps: mapStateToProps,
}

export const Sell = {
    mapDispatchToProps: mapDispatchToPropsForSell,
    mapStateToProps: mapStateToProps,
}

export const BuySell = {
    reduce,
}