import { FullUserData, WyreOrder } from "./Types";
import { AppUserProfile } from "unifyre-extension-sdk/dist/client/model/AppUserProfile";

export interface OrderState {
    order: WyreOrder,
    error?: string;
}

export interface UserPreference {
    lastFiatCurrency: string;
    lastFiatSource: string;
}

export const defaultUserPreference = {
    lastFiatCurrency: 'USD',
} as UserPreference;

export interface DashboardProps {
    initialized: boolean;
    fatalError?: string;
}

export interface AccountProps {
    id: string;
    displayName: string;
    email: string;
    wyreAccountId: string;
    accountStatus: string;
    wyrePaymentMethodStatus: string;
    lastUpdate: string;
    actionRequired: boolean;
}

export interface BuySellState {
    action: 'buy' | 'sell';
    userCryptoForInput: boolean;
    fiatSource: 'ach' | 'debit' | 'apple-pay';
    fiatCurrency: string;
    inputAmount: string;
    inputAmountBeforeSwitch?: string;
    buttonDisabled: boolean;
    errorMsg?: string;
}

export interface WyreConfig {
    env: 'test' | 'prod';
    accountId: string;
}

export interface RootState {
    data : {
        userData?: { data: FullUserData, profile: AppUserProfile },
        userPreference: UserPreference,
        wyreConfig: WyreConfig,
    },
    ui: {
        flags: {
            waiting: boolean,
        },
        dashboard: DashboardProps,
        account: AccountProps,
        buySell: BuySellState,
        order: OrderState,
    }
}