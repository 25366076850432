import React, {useEffect} from 'react';
import { OrderDispatch, Order, OrderProps } from './Order';
import {
    Page, PageTopPart, Gap, Row, InputGroupAddon, ThemedText, ThemedButton,
// @ts-ignore
} from 'unifyre-web-components';
import { intl } from 'unifyre-react-helper';
import { WyreOrder } from '../../common/Types';
import { Utils } from '../../common/Utils';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router';

function translate(order: WyreOrder) {
    console.log('ORDER STATUS', order.status)
    switch(order.status) {
        case 'FAILED':
            return intl('order-failed');
        case 'PROCESSING':
            return intl('order-processing');
        default:
            return intl('order-complete');
    }
}

function OrderComponent(props: OrderProps&OrderDispatch) {
    const {orderId, reservationId} = useParams();
    const history = useHistory();
    useEffect(() => {
        const queryOrderId = Utils.getQueryparam('orderId');
        if (queryOrderId) {
            history.replace('/order/' + queryOrderId);
        } else if (orderId) {
            props.loadOrder(orderId);
        } else if (reservationId) {
            props.loadOrderByReservation(reservationId);
        }
    }, [orderId, reservationId]);
    if (props.error) {
        return (
            <Page>
                <Gap />
                <Gap />
                <Gap />
                <Row centered withPadding>
                <ThemedText.H3>{props.error}</ThemedText.H3>
                </Row>
                <Gap />
                <Row withPadding>
                <ThemedButton text={intl('btn-open-unifyre')} onClick={props.onCloseClicked} />
                </Row>
            </Page>
        )
    }
    const translatedStatus = translate(props.order);
    const error = props.order.errorMessage ? (
        <>
        <Gap />
        <Row withPadding>
            <ThemedText.P>{props.order.errorMessage}</ThemedText.P>
        </Row>
        </>
    ) : undefined;
    const trackTx = props.order.transferId ? (
        <>
            <Gap />
            <Row withPadding>
                <ThemedButton text={intl('track-transaction')}
                onClick={() => props.onTrackTransfer(props.env, props.order.transferId)}/>
            </Row>
        </>
    ) : undefined;
    return (
        <Page>
            <PageTopPart>
                <Gap />
                <Row centered>
                <ThemedText.H3>{intl('order-details')}</ThemedText.H3>
                </Row>
                <Gap />
                <Row centered>
                    <ThemedText.H4>{intl('purchase')} {props.order.destCurrency}</ThemedText.H4>
                </Row>
                <Row centered>
                    <ThemedText.H2>{props.order.purchaseAmount} {props.order.sourceCurrency}</ThemedText.H2>
                </Row>
                <Gap />
            </PageTopPart>
            <Gap />
            <Row withPadding>
                <ThemedText.H4>{intl('order-status')}</ThemedText.H4>
            </Row>
            <Row withPadding>
                <InputGroupAddon editable={false}
                    value={translatedStatus}
                />
            </Row>
            {trackTx}
            {error}
        </Page>
    );
}

export const OrderContainer = connect(Order.mapStateToProps, Order.mapDispatchToProps)(OrderComponent);