export const stringsEn = `

your-account = Your Account
display-name-caps = DISPLAY NAME

fatal-error-heading = Cannot connect to server
fatal-error-details = Your session might be expired. Please open the Unifyre wallet again and
    try to come back here

btn-continue = Continue { $action }
btn-complete-account = Complete your account

# Fetch service
err-loading-prices = Could not load exchage rates

# Buy sell page 
address-caps = YOUR ADDRESS
balance-caps = BALANCE
fee-caps = FEE (INCLUDED)
fee-not-included = Fees will be added
you-spend-caps = YOU SPEND
you-receive-caps = YOU RECEIVE
buy-notes = Note that the quoted price is an estimate. You need network fees to run the transactions.
    You might be asked to fill up extra identification documentations to complete the transaction.
fiat-source-debit = Debit card (through USD)
fiat-source-apple-pay = Apple Pay (through USD)
fiat-source-ach = Bank account (US only)

# Order page
order-details = Order details
purchase = Purchase
order-failed = Failed
order-processing = Processing
order-complete = Completed
order-not-found = Order not found
order-status = Order status
btn-open-unifyre = Back to Unifyre
track-transaction = Track transaction on Wyre
`;